@import "./colors.css";


.sy_instance_manager_mtr {
    margin-top: 1rem;
}

.successIcon{
    color: var(--approved--color);
    font-size: 22px;
}

.cancelIcon{
    color: var(--declined--color);
    font-size: 22px;
}